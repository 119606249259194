import './App.css';
import Home from './components/home'; // Adjust the path as necessary

function App() {
    return (
        <div>
            <Home />
        </div>
    );
}

export default App;
