import React, { useState } from 'react';
import Uploader from './uploader';
import View from './view';
import Login from './Login';
import { useAuth, AuthProvider } from './AuthContext';
import './Home.css';

const Home = () => {
    const { isAuthenticated } = useAuth();
    const [view, setView] = useState('upload');

    const handleUploadClick = () => setView('upload');
    const handleViewProcessingClick = () => setView('view');

    // const url = 'http://localhost:1234';
    const url = 'https://mdmcorp.org/aws_server';


    return (
        <div className="container">
            {!isAuthenticated ? (
                <Login onLoginSuccess={() => setView('upload')} />
            ) : (
                <>
                    {view === 'upload' && (
                        <Uploader onNavigateToView={handleViewProcessingClick} url_upload={url} />
                    )}
                    {view === 'view' && (
                        <View onNavigateToUpload={handleUploadClick} url_download={url} />
                    )}
                </>
            )}
        </div>
    );
};

// Wrap the Home component in the AuthProvider
const App = () => (
    <AuthProvider>
        <Home />
    </AuthProvider>
);

export default App;
