import React, { useState, useRef, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import "./Uploader.css";

const Uploader = ({ onNavigateToView, url_upload }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileOptions, setFileOptions] = useState({});
  const [acknowledgment, setAcknowledgment] = useState("");
  const [selectedModel, setSelectedModel] = useState("gpt-4o"); // Default model
  const [prompt, setPrompt] = useState(""); // New state for prompt
  const [selectedTranscriptionModel, setSelectedTranscriptionModel] =
    useState("whisper-1"); // Default transcription model

  const abortControllerRef = useRef(null);
  const fileInputRef = useRef(null);
  const currentXhrRef = useRef(null);
  const handleTranscriptionModelChange = (e) => {
    setSelectedTranscriptionModel(e.target.value);
  };
  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    setFileOptions((prevOptions) => {
      const { [fileName]: _, ...rest } = prevOptions;
      return rest;
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleClear = () => {
    setIsRunning(false);
    setAcknowledgment("");
    setFiles([]);
    setFileOptions({});
    setPrompt(""); // Clear prompt when clearing the form
  };

  const handlesourceLanguageChange = (fileName, sourceLanguage) => {
    setFileOptions((prevOptions) => ({
      ...prevOptions,
      [fileName]: {
        ...prevOptions[fileName],
        sourceLanguage: sourceLanguage,
      },
    }));
  };

  const handleFileChange = (e) => {
    const newFiles = [...e.target.files];
    let duplicateFound = false;
    let invalidFileFound = false;
    const newFileOptions = { ...fileOptions };
    const allowedExtensions = [".mp3", ".wav", ".doc", ".docx", ".pdf"];
    const uniqueFiles = newFiles.filter((newFile) => {
      const fileExtension = newFile.name
        .slice(((newFile.name.lastIndexOf(".") - 1) >>> 0) + 2)
        .toLowerCase();
      const isAllowed = allowedExtensions.includes(`.${fileExtension}`);
      if (!isAllowed) {
        invalidFileFound = true;
        setAcknowledgment(
          (prev) => `${prev} Invalid file type: ${newFile.name}`
        );
        return false;
      }
      const isDuplicate = files.some((file) => file.name === newFile.name);
      if (isDuplicate) {
        duplicateFound = true;
        setAcknowledgment("Duplicate files were detected and not added.");
        return false; // Exclude duplicate file
      }

      newFileOptions[newFile.name] = newFileOptions[newFile.name] || {
        transcribe: false,
        translate: false,
        target_language: "",
        progress: 0,
        sourceLanguage: "None", // Add this for all files
      };
      return true; // Include unique file
    });

    const uploadedFiles = [...files, ...uniqueFiles];
    setFiles(uploadedFiles);
    setFileOptions(newFileOptions);

    if (invalidFileFound) {
      setAcknowledgment((prev) => "Rejected due to invalid file types.");
    } else {
      setAcknowledgment(
        duplicateFound ? "Duplicate files were detected and not added." : ""
      );
    }

    // Reset file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handletarget_languageChange = (fileName, target_language) => {
    setFileOptions((prevOptions) => ({
      ...prevOptions,
      [fileName]: {
        ...prevOptions[fileName],
        target_language: target_language,
      },
    }));
  };

  const handleCheckboxChange = (fileName, option) => {
    setFileOptions((prevOptions) => ({
      ...prevOptions,
      [fileName]: {
        ...prevOptions[fileName],
        [option]: !prevOptions[fileName][option],
      },
    }));
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  // New handler for prompt changes
  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    setAcknowledgment("Process started...");

    if (files.length === 0) {
      setAcknowledgment("ERROR: No files selected");
      return;
    }

    abortControllerRef.current = new AbortController();

    // Check if options are valid for all files
    const allOptionsValid = files.every((file) => {
      const options = fileOptions[file.name];
      const hasOperation = options.transcribe || options.translate;
      const hasTargetLanguage = options.translate
        ? !!options.target_language
        : true; // Only check target language if translating
      return hasOperation && hasTargetLanguage;
    });

    if (!allOptionsValid) {
      setAcknowledgment(
        "ERROR: Please select operation and target language for all files."
      );
      return;
    }

    try {
      setIsRunning(true);

      for (const file of files) {
        const options = fileOptions[file.name];
        const operation =
          options.transcribe && options.translate
            ? "transcribe-translate"
            : options.transcribe
            ? "transcribe"
            : options.translate
            ? "translate"
            : "";

        const formData = new FormData();
        formData.append("files", file);
        formData.append("operations", operation);
        formData.append("target_language", options.target_language || "");
        formData.append("source_language", options.sourceLanguage || "None");
        formData.append("ai_model", selectedModel); // Add the selected model to form data
        formData.append("prompt", prompt); // Add the prompt to form data
        formData.append("transcription_model", selectedTranscriptionModel); // Add the selected transcription model

        // Create a new XMLHttpRequest for each file
        const xhr = new XMLHttpRequest();
        currentXhrRef.current = xhr; // Store current XHR in the ref
        xhr.open("POST", `${url_upload}/upload`, true);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const total = event.total;
            const loaded = event.loaded;
            const progress = Math.round((loaded / total) * 100);

            setFileOptions((prevOptions) => {
              const newOptions = { ...prevOptions };
              newOptions[file.name].progress = progress;
              return newOptions;
            });
          }
        };

        xhr.onload = async () => {
          if (xhr.status === 200) {
            const result = JSON.parse(xhr.responseText);
            setAcknowledgment(`Processing: ${result.message}`);
          } else {
            setAcknowledgment(`Error: ${xhr.statusText}`);
          }
        };

        xhr.onerror = () => {
          setAcknowledgment("Error: Request failed");
        };

        // Check if the request was aborted
        xhr.onabort = () => {
          setAcknowledgment("Upload stopped.");
        };

        xhr.send(formData);

        // Optionally wait for this request to finish before moving to the next
        await new Promise((resolve) => {
          xhr.onloadend = resolve;
        });
      }
    } catch (error) {
      setAcknowledgment(`Error: ${error.message}`);
      handleHalt();
    } finally {
      setIsRunning(false);
      setAcknowledgment("Files upload complete.");
    }
  };

  const handleHalt = () => {
    // Abort the ongoing fetch request
    if (currentXhrRef.current) {
      currentXhrRef.current.abort();
    }
    setIsRunning(false);
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (currentXhrRef.current) {
        currentXhrRef.current.abort();
      }
    };
  }, []);

  return (
    <div className="App">
      <nav>
        <button onClick={onNavigateToView}>View Processed Files</button>
      </nav>
      <h1>Upload Files</h1>
      <form>
        <div className="model-selection">
          <label htmlFor="ai-model">Translation Model:</label>
          <select
            id="ai-model"
            value={selectedModel}
            onChange={handleModelChange}
            disabled={isRunning}
          >
            <option value="claude-3-5-haiku-20241022">Claude 3.5 Haiku</option>
            <option value="claude-3-5-sonnet-20240620">
              Claude 3.5 Sonnet
            </option>
            <option value="claude-3-7-sonnet-20250219">
              Claude 3.7 Sonnet
            </option>
            <option value="gpt-4">GPT-4</option>
            <option value="gpt-4o">GPT-4o</option>
            <option value="gpt-4.5-preview">GPT-4.5 Preview</option>
            <option value="o3-mini">o3-mini</option>
          </select>
        </div>
        <div className="model-selection">
          <label htmlFor="transcription-model">Audio Transcription Model:</label>
          <select
            id="transcription-model"
            value={selectedTranscriptionModel}
            onChange={handleTranscriptionModelChange}
            disabled={isRunning}
          >
            <option value="whisper-1">Whisper-1 (Default)</option>
            <option value="gpt-4o-transcribe">GPT-4o Transcribe</option>
            <option value="gpt-4o-mini-transcribe">
              GPT-4o Mini Transcribe
            </option>
          </select>
        </div>

        {/* New prompt field */}
        <div className="prompt-field">
          <label htmlFor="prompt">Translation Prompt:</label>
          <textarea
            id="prompt"
            value={prompt}
            onChange={handlePromptChange}
            placeholder="Enter your instructions for the AI model..."
            disabled={isRunning}
            rows="3"
          />
        </div>

        <input
          type="file"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {!isRunning && <button onClick={handleFileSubmit}>Start</button>}
        {isRunning && <button onClick={handleHalt}>Stop</button>}
        {!isRunning && <button onClick={handleClear}>Clear</button>}
      </form>
      {isRunning && <FaSpinner className="spinner" />}
      {acknowledgment && (
        <p style={{ fontSize: "20px", color: "white" }}>{acknowledgment}</p>
      )}
      {files.length > 0 && (
        <div>
          <h2>File Options</h2>
          <table>
            <thead>
              <tr>
                <th>File</th>
                <th>Transcribe</th>
                <th>Translate</th>
                <th>Target Language</th>
                <th>Source Language</th>
                <th>Upload Progress</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file) => (
                <tr key={file.name}>
                  <td>{file.name}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={fileOptions[file.name]?.transcribe || false}
                      onChange={() =>
                        handleCheckboxChange(file.name, "transcribe")
                      }
                      disabled={isRunning}
                      style={{ transform: "scale(2)" }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={fileOptions[file.name]?.translate || false}
                      onChange={() =>
                        handleCheckboxChange(file.name, "translate")
                      }
                      disabled={isRunning}
                      style={{ transform: "scale(2)" }}
                    />
                  </td>
                  <td>
                    {fileOptions[file.name]?.translate && (
                      <input
                        type="text"
                        value={fileOptions[file.name]?.target_language || ""}
                        onChange={(e) =>
                          handletarget_languageChange(file.name, e.target.value)
                        }
                        placeholder="Enter Target Language"
                        disabled={isRunning}
                        style={{ width: "150px" }}
                      />
                    )}
                  </td>
                  <td>
                    <select
                      value={fileOptions[file.name]?.sourceLanguage || "None"}
                      onChange={(e) =>
                        handlesourceLanguageChange(file.name, e.target.value)
                      }
                      disabled={isRunning}
                      style={{ width: "100px" }}
                    >
                      <option value="None">None</option>
                      <option value="en">English</option>
                      <option value="ur">Urdu</option>
                      <option value="ar">Arabic</option>
                      <option value="bg">Bulgarian</option>
                      <option value="hr">Croatian</option>
                      <option value="cs">Czech</option>
                      <option value="da">Danish</option>
                      <option value="nl">Dutch</option>
                      <option value="et">Estonian</option>
                      <option value="fi">Finnish</option>
                      <option value="fr">French</option>
                      <option value="de">German</option>
                      <option value="el">Greek</option>
                      <option value="hu">Hungarian</option>
                      <option value="ga">Irish</option>
                      <option value="it">Italian</option>
                      <option value="lv">Latvian</option>
                      <option value="lt">Lithuanian</option>
                      <option value="mt">Maltese</option>
                      <option value="pl">Polish</option>
                      <option value="pt">Portuguese</option>
                      <option value="ro">Romanian</option>
                      <option value="sk">Slovak</option>
                      <option value="sl">Slovenian</option>
                      <option value="es">Spanish</option>
                      <option value="sv">Swedish</option>
                      <option value="no">Norwegian</option>
                      <option value="is">Icelandic</option>
                      <option value="ru">Russian</option>
                      <option value="uk">Ukrainian</option>
                      <option value="sr">Serbian</option>
                      <option value="mk">Macedonian</option>
                      <option value="sq">Albanian</option>
                    </select>
                  </td>
                  <td>
                    {fileOptions[file.name]?.progress !== undefined && (
                      <div className="progress-container">
                        <span>{fileOptions[file.name].progress}%</span>
                        <progress
                          value={fileOptions[file.name].progress}
                          max="100"
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    {!isRunning && (
                      <button onClick={() => handleRemoveFile(file.name)}>
                        Remove
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Uploader;
