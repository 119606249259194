import React, { useState } from 'react';
import './View.css'; // Import the CSS file
import useRunOnce from './useRunOnce';


const View = ({ onNavigateToUpload, url_download }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [showJobs, setShowJobs] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const jobsPerPage = 10;


    const handleDownloadTranscription = async (docId, fileName) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${url_download}/download/transcription/${docId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch transcription');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const filename = `${fileName}_transcription.docx`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadTranslation = async (docId, fileName) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${url_download}/download/translation/${docId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch translation');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const filename = `${fileName}_translation.docx`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    const handleDownloadSRT = async (docId, fileName) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${url_download}/download/srt/${docId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch SRT file');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const srtFilename = `${fileName}.srt`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', srtFilename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteJob = async (docId) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${url_download}/jobs/${docId}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Failed to delete job');
            }
            // Remove the job from the local state
            setJobs((prevJobs) => prevJobs.filter((job) => job.id !== docId));
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    const fetchJobIds = async () => {
        setLoading(true);
        setError(null);
        setJobs([]);
        try {
            const response = await fetch(`${url_download}/jobs`);
            if (response.statusText === "Not Found") {
                throw new Error('No processes found! Please start some processes first.');
            } else if (response.ok === false) {
                throw new Error(response.statusText);
            }
    
            const data = await response.json();
    
            // Sort jobs by ID in descending order
            const sortedJobs = data.sort((a, b) => b.id - a.id);
    
            setJobs(sortedJobs);
            setShowJobs(true);
            setCurrentPage(0); // Reset to first page
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    // Pagination logic
    const totalPages = Math.ceil(jobs.length / jobsPerPage);
    const startIndex = currentPage * jobsPerPage;
    const currentJobs = jobs.slice(startIndex, startIndex + jobsPerPage);





    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage((prev) => prev - 1);
        }
    };
    const renderJobDetails = (job) => {
        const typeLabels = {
            pdf: 'Page Number',
            audio: 'Audio Minute',
            doc: 'Paragraph Number',
        };

        const label = typeLabels[job.type] || 'Unknown Type';
        return (
            <td>
                {job.progress}%<hr />
                {label}: {job.page_num + 1}/{job.total}
            </td>
        );
    };

    useRunOnce(fetchJobIds);


    return (
        <div className="container">
            <nav>
                <button onClick={onNavigateToUpload}>Upload Files</button>
            </nav>

            {error && <div className="error">Error: {error}</div>}


            <button onClick={fetchJobIds}>
                Refresh
            </button>

            {loading && <div>Loading...</div>}

            {showJobs && currentJobs.length > 0 && (
                <div>
                    <h2>All Job IDs</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Batch Job ID</th>
                                <th>Timestamp</th>
                                <th>Filename</th>
                                <th>Progress</th>
                                <th>Cost</th>
                                <th>Download</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentJobs.map((job, index) => (
                                <tr key={index}>
                                    <td>{job.batch_job_id}</td>
                                    <td>{new Date(job.timestamp).toLocaleString()}</td>
                                    <td>{job.filename}</td>
                                    {renderJobDetails(job)}
                                    <td>${job.cost.toFixed(4)}</td>
                                    <td>
                                        {job.error && (<div className="error">Error: {job.error}</div>)}
                                        {job.progress === 100 && job.transcription === 'yes' && (
                                            <button onClick={() => handleDownloadTranscription(job.id, job.filename)}>
                                                Transcription
                                            </button>
                                        )}
                                        {job.progress === 100 && job.translation === 'yes' && (
                                            <button onClick={() => handleDownloadTranslation(job.id, job.filename)}>
                                                Translation
                                            </button>
                                        )}
                                        {job.type === 'audio' && job.progress === 100 && (
                                            <button onClick={() => handleDownloadSRT(job.id, job.filename)}>
                                                SRT
                                            </button>
                                        )}
                                    </td>
                                    <td>
                                        {job.progress === 100 && (<button onClick={() => handleDeleteJob(job.id)}>Delete</button>)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>Previous</button>
                        <span> Page {currentPage + 1} of {totalPages} </span>
                        <button onClick={handleNextPage} disabled={currentPage >= totalPages - 1}>Next</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default View;
